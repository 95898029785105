import config from "../config";
import RequestAdmin from "../request";

const urlCicloEscolares = `${config.URL_BASE}ciclo-escolar`;

export async function getCiclosEscolaresColegio(id: number) {

    const filter = {
        where: {
            idColegio: id
        },
        order: ['year ASC']
    };

    try {
        const request = new RequestAdmin();
        return request.executeGet(`${urlCicloEscolares}?filter=${JSON.stringify(filter)}`)
    } catch (err) {
        console.error('Error en obtener Estudiante', err);
        return null;
    }
}
