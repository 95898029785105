import axios from "axios";

class JwtService {
  async getToken() {

  }
}

export default class RequestAdmin {
  // eslint-disable-next-line class-methods-use-this
  async executeGet(url: string) {
    try {
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      console.error('error executeGet', error);
      return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async executePostPutPatch(url: string, body: any, method: string, user = false) {
    let response
    try {
      const jwt = new JwtService()
      const token = await jwt.getToken()
      if (method === 'POST') {
        response = await axios.post(
          url, body,
          {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              // Authorization: `Bearer ${token}`,
            },
          },
        )
        if (user) return response
        return response.data
      }
      if (method === 'PATCH') {
        response = await axios.patch(
          url, body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        return response.data || true
      }
      response = await axios.put(
        url, body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return response.data
    } catch (error) {
      console.error('error executePostPut', error)
      if (method === 'POST' && user) { // @ts-ignore
        return error.response
      }
      return null
    }
  }
}
