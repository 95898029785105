import { login } from "../utils/Authentication/auth";
import {getCiclosEscolaresColegio} from "../utils/Colegios/colegiosUtils";

// @ts-ignore
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

export function manageUserData(user: any) {
    if (!user || !user?.role) {
        return getUserData();
    }

    return user;
}

export function getRouteRedirect(userData: any, navigate: any) {
    if(!userData) {
        navigate('/login');
    } else if (userData.role === 'ADMIN') {
        navigate('/cobros-y-pagos/vista-general');
    } else if (userData.role === 'USER') {
        navigate('/cobros-y-pagos/vista-general');
    } else {
        navigate('/unauthorized');
    }
}

interface Company {
    id: number;
    mongoId: string;
    active: boolean;
    name: string;
    nit: string;
    phone: string;
    email: string;
    companyStyle: any;
    roles: Role[];
    ciclos: any[];
}

interface Role {
    name: string;
    key: string;
}

export interface IUserProps {
    id: string;
    role: string;
    username: string;
    name: string;
    surname: string;
    position: string;
    email?: string;
    src?: string;
    srcSet?: string;
    password?: string;
    company?: Company;
    token?: string;
}

export interface IUserLogin {
    usuario: IUserProps | null;
    success: boolean;
    error: string;
}

export async function autenticar(username: string, password: string) : Promise<IUserLogin> {
    const loginResponse = await login(username, password);
    const response: IUserLogin = {
        usuario: null,
        success: false,
        error: ''
    }

    if (loginResponse.status === 200) {
        const user = loginResponse.data;
        const ciclos = await getCiclosEscolaresColegio(user.company.idSql)
        const cicloActivo = ciclos.find((ciclo: any) => ciclo.vigente === true);

        response.usuario = {
            id: user.id,
            role: user.role,
            username: user.username,
            name: user.name,
            surname: user.surname,
            position: user.position,
            email: user.email,
            src: user.src,
            srcSet: user.srcSet,
            company: {
                id: user.company.idSql,
                mongoId: user.company.id,
                active: user.company.active,
                name: user.company.name,
                nit: user.company.nit,
                phone: user.company.phone,
                email: user.company.email,
                companyStyle: user.company.companyStyle,
                roles: user.company.roles,
                ciclos: ciclos,
            },
        };
        localStorage.setItem("userData", JSON.stringify(response.usuario));
        localStorage.setItem("cicloActivo", JSON.stringify(cicloActivo));
        response.success = true;
    } else {
        if (loginResponse && loginResponse.status === 401) {
            response.error = loginResponse.data.error.message;
        } else {
            response.error = 'Error en la autenticación, intente nuevamente!';
        }
    }

    return response;
}
