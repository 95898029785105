import React, {
  FC,
  useCallback,
  useContext,
  useState,
  startTransition,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useFormik } from "formik";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import AuthContext from "../../../contexts/authContext";
import {
  autenticar,
  IUserProps,
  IUserLogin,
} from "../../../auth/utils";
import Spinner from "../../../components/bootstrap/Spinner";
import logoProyecto from "../../../assets/logos/Colegios/SagradaEnseñanza.jpg";
import { getRouteRedirect } from "../../../auth/utils";

interface ILoginHeaderProps {
  isNewUser?: boolean;
}

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser = false }) => {
  if (isNewUser) {
    return (
      <>
        <div className="text-center h1 fw-bold mt-5">Create Account,</div>
        <div className="text-center h4 text-muted mb-5">
          Sign up to get started!
        </div>
      </>
    );
  }
  return (
    <>
      <div className="text-center h1 fw-bold mt-5">Bienvenido,</div>
      <div className="text-center h4 text-muted mb-5">
        Logeate para continuar!
      </div>
    </>
  );
};

interface ILoginProps {
  isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {
  const { setUser } = useContext(AuthContext);
  const { darkModeStatus } = useDarkMode();
  const [signInPassword, setSignInPassword] = useState<boolean>(false);
  const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

  const navigate = useNavigate();
  const handleOnClick = useCallback(
    (user: Partial<IUserProps> | null) => {
      if (setUser) {
        setUser(user);
      }

      startTransition(() => {
        getRouteRedirect(user, navigate);
      });
    },
    [navigate],
  );

  const usernameCheck = (username: string) => {
	  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	  return emailRegex.test(username);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loginUsername: "",
      loginPassword: "",
    },
    validate: (values) => {
      const errors: { loginUsername?: string; loginPassword?: string } = {};

      if (!values.loginUsername) {
        errors.loginUsername = "Required";
      }

      if (!values.loginPassword) {
        errors.loginPassword = "Required";
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      if (usernameCheck(values.loginUsername)) {
        const login: IUserLogin = await autenticar(values.loginUsername, values.loginPassword);
        if (login.success) {
          const user: IUserProps | null = login.usuario;
          if (setUser) {
            setUser(user);
          }
          handleOnClick(user);
        } else {
          formik.setFieldError(
              "loginPassword",
              login.error,
          );
        }
      }
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleContinue = () => {
    setIsLoading(true);
    setTimeout(() => {
      if (!usernameCheck(formik.values.loginUsername)) {
        formik.setFieldError("loginUsername", "Correo y/o usuario inválido.");
      } else {
        setSignInPassword(true);
      }
      setIsLoading(false);
    }, 1000);
  };

  return (
    <PageWrapper
      isProtected={false}
      title={singUpStatus ? "Sign Up" : "Login"}
      className={classNames({
        "bg-dark": !singUpStatus,
        "bg-light": singUpStatus,
      })}
    >
      <Page className="p-0">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
            <Card className="shadow-3d-dark" data-tour="login-page">
              <CardBody>
                <div className="text-center my-5">
                  <Link
                    to="/"
                    className={classNames(
                      "text-decoration-none  fw-bold display-2",
                      {
                        "text-dark": !darkModeStatus,
                        "text-light": darkModeStatus,
                      },
                    )}
                    aria-label="Facit"
                  >
                    {/*<Logo width={200} />*/}
                    <div
                      className={"text-center"}
                      style={{
                        display: "inline-block",
                        padding: "10px", // Espacio alrededor de la imagen dentro del contenedor
                        backgroundColor: "transparent", // Fondo blanco o el color que prefieras
                        borderRadius: "20px", // Bordes redondeados
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)", // Sombra para darle un efecto flotante
                      }}
                    >
                      <img
                        src={logoProyecto}
                        alt="Logo"
                        width={250}
                        height={200}
                      />
                    </div>
                  </Link>
                </div>
                <LoginHeader isNewUser={singUpStatus} />
                <form className="row g-4">
                  {singUpStatus ? (
                    <>
                      <div className="col-12">
                        <FormGroup
                          id="signup-email"
                          isFloating
                          label="Your email"
                        >
                          <Input type="email" autoComplete="email" />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup
                          id="signup-name"
                          isFloating
                          label="Your name"
                        >
                          <Input autoComplete="given-name" />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup
                          id="signup-surname"
                          isFloating
                          label="Your surname"
                        >
                          <Input autoComplete="family-name" />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup
                          id="signup-password"
                          isFloating
                          label="Password"
                        >
                          <Input type="password" autoComplete="password" />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <Button
                          color="info"
                          className="w-100 py-3"
                          onClick={handleOnClick}
                        >
                          Sign Up
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12">
                        <FormGroup
                          id="loginUsername"
                          isFloating
                          label="Ingresa tu correo"
                          className={classNames({
                            "d-none": signInPassword,
                          })}
                        >
                          <Input
                            autoComplete="username"
                            value={formik.values.loginUsername}
                            isTouched={formik.touched.loginUsername}
                            invalidFeedback={formik.errors.loginUsername}
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={() => {
                              formik.setErrors({});
                            }}
                          />
                        </FormGroup>
                        {signInPassword && (
                          <div className="text-center h4 mb-3 fw-bold">
                            Hola, {formik.values.loginUsername}.
                          </div>
                        )}
                        <FormGroup
                          id="loginPassword"
                          isFloating
                          label="Contraseña"
                          className={classNames({
                            "d-none": !signInPassword,
                          })}
                        >
                          <Input
                            type="password"
                            autoComplete="current-password"
                            value={formik.values.loginPassword}
                            isTouched={formik.touched.loginPassword}
                            invalidFeedback={formik.errors.loginPassword}
                            validFeedback="Looks good!"
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        {!signInPassword ? (
                          <Button
                            color="warning"
                            className="w-100 py-3"
                            isDisable={!formik.values.loginUsername}
                            onClick={handleContinue}
                          >
                            {isLoading && <Spinner isSmall inButton isGrow />}
                            Continuar
                          </Button>
                        ) : (
                          <Button
                            color="warning"
                            className="w-100 py-3"
                            onClick={formik.handleSubmit}
                          >
                            Ingresar
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </form>
              </CardBody>
            </Card>
            <div className="text-center">
              <a
                href="/"
                className={classNames("text-decoration-none me-3", {
                  "link-light": singUpStatus,
                  "link-dark": !singUpStatus,
                })}
              >
                Privacy policy
              </a>
              <a
                href="/"
                className={classNames("link-light text-decoration-none", {
                  "link-light": singUpStatus,
                  "link-dark": !singUpStatus,
                })}
              >
                Terms of use
              </a>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool,
};

export default Login;
