import React, { FC, HTMLAttributes } from 'react';
import PropTypes from 'prop-types';

interface IOptionProps extends HTMLAttributes<HTMLOptionElement> {
	children: string;
	value?: string | number;
	disabled?: boolean;
	ariaLabelledby?: string | null;
}

const Option: FC<IOptionProps> = ({ children, value = '', disabled = false, ariaLabelledby = null, ...props }) => {
	return (
		<option
			value={value}
			disabled={disabled}
			aria-labelledby={ariaLabelledby || children}
			{...props}>
			{children}
		</option>
	);
};

Option.propTypes = {
	children: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	disabled: PropTypes.bool,
	ariaLabelledby: PropTypes.string,
};

export interface IOptionsProps {
	list: {
		value?: string | number;
		text?: string | number;
		label?: string | number;
		id?: string | number;
		name?: string | number;
		nombre?: string | number;
	}[];
}

export const Options: FC<IOptionsProps> = ({ list }) => {
	return list?.map((item) => {
		// Prioriza `value` y `text` si están presentes, de lo contrario usa `id`, `name` y `nombre`
		const value = item.value || item.id;
		const text = item.text || item.label || item.name || item.nombre;

		return (
			// @ts-ignore
			<Option key={value} value={value} {...item}>
				{text}
			</Option>
		);
	});
};

Options.propTypes = {
	// @ts-ignore
	list: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	).isRequired,
};

export default Option;
