import React from 'react';
import {ThreeCircles} from "react-loader-spinner";
import {isMobile} from "../isMobileValidation";

const loaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: isMobile() ? '100vw' : '110vw',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'transparent', // Fondo blanco muy ligero y semi-transparente
    backdropFilter: 'blur(10px)', // Efecto borroso
};

const LoaderAudio = () => {
    return (
        // @ts-ignore
        <div style={loaderStyle}>
            <ThreeCircles color='#61DBFB'/>
        </div>
    );
};

export default LoaderAudio;
