import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';
import logoProyect from "../../assets/logos/Colegios/SagradaEnseñanza.jpg";

interface IBrandProps {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}
const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
	return (
		<div className='brand'>
			<div className='brand-logo'>
				<h1 className='brand-title '>
					<Link to='/cobros-y-pagos/vista-general' aria-label='Logo'>
						<div style={{
							marginTop: '3vh',
							marginLeft: '2vw',
							display: 'inline-block',
							padding: '10px',            // Espacio alrededor de la imagen dentro del contenedor
							backgroundColor: '#ffffff', // Fondo blanco o el color que prefieras
							borderRadius: '10px',       // Bordes redondeados
							boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)' // Sombra para darle un efecto flotante
						}}>
							<img
								src={logoProyect}
								alt="Logo"
								width={60}
								height={50}
							/>
						</div>
						{/*<Logo height={32} />*/}
					</Link>
				</h1>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close'/>
				<Icon icon='LastPage' className='brand-aside-toggle-open'/>
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
