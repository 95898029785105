import React, { useEffect, useState } from "react";
import Select from "../../bootstrap/forms/Select";
import { findCiclos } from "../../../utils/CatalogosUtils/CatalogosUtils";

export const SeleccionableCiclos = () => {
  const [ciclos, setCiclos] = useState([]);
  const [cicloSeleccionado, setCicloSeleccionado] = useState("");
  const cicloActivo = JSON.parse(localStorage.getItem("cicloActivo") || "{}");
  const usuario = JSON.parse(localStorage.getItem("userData") || "{}");

  useEffect(() => {
    if (cicloActivo && cicloActivo.id) {
      setCicloSeleccionado(cicloActivo.id.toString());
    }
  }, []);

  useEffect(() => {
    const loadCiclos = async () => {
      try {
        const filterCiclos = {
          where: {
            and: [{ idColegio: usuario.company.id }]
          }
        };
        const ciclosData = await findCiclos(filterCiclos, true);
        setCiclos(ciclosData || []);
      } catch (error) {
        console.error("Error al cargar ciclos:", error);
      }
    };

    loadCiclos();
  }, []);

  // @ts-ignore
  const handleChangeCiclo = (event) => {
    try {
      const selectedCicloId = event.target.value;
      // @ts-ignore
      const selectedCiclo = ciclos.find(ciclo => ciclo.id === parseInt(selectedCicloId));

      if (selectedCiclo) {
        // Guardar el ciclo completo en localStorage
        localStorage.setItem("cicloActivo", JSON.stringify(selectedCiclo));

        // Actualizar el ciclo seleccionado en el estado
        setCicloSeleccionado(selectedCicloId);
        console.log("Ciclo seleccionado:", selectedCiclo);

        // Verificar que `globalThis.window` exista antes de recargar
        if (typeof globalThis.window !== "undefined") {
          // Asegurarse de que la recarga ocurra después de que el ciclo se haya almacenado
          globalThis.window.location.reload();
        }
      } else {
        console.error("Ciclo no encontrado");
      }
    } catch (error) {
      console.error("Error al seleccionar el ciclo:", error);
    }
  };
  return (
    <div>
      <div>
        <Select ariaLabel="Select an option" value={cicloSeleccionado} onChange={handleChangeCiclo}>
          {ciclos.map(ciclo => ( // @ts-ignore
            <option key={ciclo.id} value={ciclo.id}>
              {/*@ts-ignore*/}
              {ciclo.nombre}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};
