import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import { isMobile } from '../../../utils/isMobileValidation';
import { SeleccionableCiclos } from '../../../components/Seleccionables/SeleccionableCiclos/SeleccionableCiclos';

interface IDashboardHeaderProps {
	isDashboardPage: boolean;
}
const DashboardHeader = ({ isDashboardPage }: IDashboardHeaderProps) => {
	return (
		<Header>
			{!isMobile() && (
				<HeaderLeft>
					<div className='row'>
						<div className='col-4'>
							{isDashboardPage && <SeleccionableCiclos />}
						</div>
						<div
							className={isDashboardPage ? 'col-8' : 'col-12'}
							style={{ marginTop: '7px' }}>
							Sistema de Integración de Pagos de Colegiaturas
						</div>
					</div>
				</HeaderLeft>
			)}
			<CommonHeaderRight />
		</Header>
	);
};

export default DashboardHeader;
