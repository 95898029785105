import React, { useRef, useState, cloneElement, FC, ElementType, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import {
	omit,
	pick,
	TransitionTimeouts,
	TransitionPropTypeKeys,
	TransitionStatuses,
	tagPropType,
} from './utils';

const transitionStatusToClassHash = {
	[TransitionStatuses.ENTERING]: 'collapsing',
	[TransitionStatuses.ENTERED]: 'collapse show',
	[TransitionStatuses.EXITING]: 'collapsing',
	[TransitionStatuses.EXITED]: 'collapse',
};

const getTransitionClass = (status: string) => {
	return transitionStatusToClassHash[status] || 'collapse';
};

const getHeight = (node: { scrollHeight: any } | null) => {
	return node ? node.scrollHeight : 0;
};

interface ICollapseProps extends Record<string, any> {
	tag?: ElementType | any;
	isOpen?: boolean;
	className?: string;
	isNavbar?: boolean;
	children: ReactNode;
	isChildClone?: boolean;
	timeout?: number | { enter?: number; exit?: number };
	appear?: boolean;
	enter?: boolean;
	exit?: boolean;
}

const Collapse: FC<ICollapseProps> = ({
	tag: Tag = 'div',
	isOpen = false,
	className,
	isNavbar = false,
	children,
	isChildClone = false,
	timeout = TransitionTimeouts.Collapse,
	appear = false,
	enter = true,
	exit = true,
	...props
	}) => {
	const ref = useRef(null);
	const NODE = ref.current;

	const [height, setHeight] = useState<number | null>(null);

	const onEntering = (isAppearing: any) => {
		setHeight(getHeight(NODE));
		if (props.onEntering) {
			props.onEntering(NODE, isAppearing);
		}
	};

	const onEntered = (isAppearing: any) => {
		setHeight(null);
		if (props.onEntered) {
			props.onEntered(NODE, isAppearing);
		}
	};

	const onExit = () => {
		setHeight(getHeight(NODE));
		if (props.onExit) {
			props.onExit(NODE);
		}
	};

	const onExiting = () => {
		if (NODE) {
			// @ts-ignore
			NODE.offsetHeight; // Trigger reflow
			setHeight(0);
		}
		if (props.onExiting) {
			props.onExiting(NODE);
		}
	};

	const onExited = () => {
		setHeight(null);
		if (props.onExited) {
			props.onExited(NODE);
		}
	};

	const transitionProps = pick(props, TransitionPropTypeKeys);
	const childProps = omit(props, TransitionPropTypeKeys);

	return (
		<Transition
			nodeRef={ref}
			{...transitionProps}
			in={isOpen}
			onEntering={onEntering}
			onEntered={onEntered}
			onExit={onExit}
			onExiting={onExiting}
			onExited={onExited}
			appear={appear}
			enter={enter}
			exit={exit}
			timeout={timeout} // Asegúrate de que timeout siempre tenga un valor
		>
			{(status) => {
				const collapseClass = getTransitionClass(status);
				const classes = classNames(className, collapseClass, isNavbar && 'navbar-collapse');
				const style = height === null ? null : { height };

				if (isChildClone) {
					// @ts-ignore
					return cloneElement(children, {
						ref,
						// @ts-ignore
						style: { ...childProps.style, ...style },
						// @ts-ignore
						className: classNames(classes, children.props.className),
						...childProps,
					});
				}

				return (
					<Tag
						{...childProps}
						// @ts-ignore
						style={{ ...childProps.style, ...style }}
						className={classes}
						ref={ref}
					>
						{children}
					</Tag>
				);
			}}
		</Transition>
	);
};

Collapse.propTypes = {
	// @ts-ignore
	...Transition.propTypes,
	isOpen: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	tag: tagPropType,
	className: PropTypes.node,
	isNavbar: PropTypes.bool,
	isChildClone: PropTypes.bool,
};

export default Collapse;
