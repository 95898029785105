import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IUserProps } from '../auth/utils';

export interface IAuthContextProps {
	user: Partial<IUserProps> | null;
	setUser?: (user: Partial<IUserProps> | null) => void; // Actualiza aquí
	userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<Partial<IUserProps> | null>(() => {
		const storedUserData = localStorage.getItem('userData');
		if (storedUserData) {
			try {
				return JSON.parse(storedUserData);
			} catch (error) {
				console.error('Error parsing user data from localStorage:', error);
				return null;
			}
		}
		return null;
	});

	const [userData, setUserData] = useState<Partial<IUserProps>>({});
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (user) {
			setUserData(user);
		} else {
			localStorage.removeItem('userData');
			setUserData({});
		}
		setIsLoading(false)
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
			isLoading,
		}),
		[user, userData, isLoading],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
