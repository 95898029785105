import React from "react";
import Header, { HeaderLeft } from "../../../../layout/Header/Header";
import Navigation from "../../../../layout/Navigation/Navigation";
import { catalogos } from "../../../../menu";
import CommonHeaderRight from "../CommonHeaderRight";
import { SeleccionableCiclos } from "../../../../components/Seleccionables/SeleccionableCiclos/SeleccionableCiclos";

const HeaderCatalogos = () => {

  return (
    <Header>
      <HeaderLeft>
        <div className="row">
          <div className="col-8">
            <Navigation id={`${catalogos.catalogos.id}top-menu`} menu={catalogos.catalogos.subMenu} horizontal={true} />
          </div>
          <div className="col-4" style={{ marginTop: "5px" }}>
            <SeleccionableCiclos />
          </div>
        </div>
      </HeaderLeft>
      <CommonHeaderRight />
    </Header>
  );
};

export default HeaderCatalogos;
