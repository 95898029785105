import config from '../config';
import RequestAdmin from '../request';
import showNotificationError from '../mensajes/showNotificationError';
import { TColor } from '../../type/color-type';

interface IParametrosCatalogos {
	id: string;
	nombre: string;
	icon: string;
	nombreCreacion: string;
	color: any;
}

export interface ICatalogosIndex {
	parametroCatalogo: IParametrosCatalogos;
	headers: JSX.Element;
	rows: (
		catalogo: any,
		index: number,
		currentPage: number,
		perPage: number
	) => JSX.Element;
	itemSeleccionado: ICatalogo | null;
	offCanvasOpen: boolean;
	setOffCanvasOpen: (open: boolean) => void;
}

interface catalogo {
	color: TColor | 'link' | 'brand' | 'brand-two' | 'storybook' | undefined;
	id: string;
	icon: string;
	nombreCreacion: string;
}

export interface ICreacionCatalogos {
	catalogo: catalogo;
	grados: any[];
	ciclos: any[];
	niveles: any[];
	isRefresh: () => void;
	itemSeleccionado: ICatalogo | null;
	offCanvasOpen: boolean;
	setOffCanvasOpen: (open: boolean) => void;
}

export interface ICatalogo {
	id: string;
	nombre: string;
	nombreCreacion: string;
	icon: string;
	color: string;
	descripcion: string;
	fechaPago: string;
	tipo?: string;
	valor?: string;
	diaMaximo?: string;
	fechaMaxima?: string;
	year?: string;
	idNivel?: string;
	idCiclo?: string;
	vigente?: string;
	grado?: string;
	estado?: string;
	nivel: string;
}

export interface ICatalogoCobros {
	id: number;
	fechaPago: string;
	descripcion: string;
	idCiclo: number;
	idColegio: number;
	idNivel: number;
	tipo: string;
	valor: number;
	nombreNivel: string;
	nombreCiclo: string;
}

export interface ICatalogoDescuentos {
	diaMaximo: number;
	estado: boolean;
	fechaMaxima: string;
	id: number;
	idCiclo: number;
	nombre: string;
	tipo: string;
	valor: number;
}

export interface ICatalogoGrados {
	nombreNivel: any;
	estado: boolean;
	id: number;
	idColegio: number;
	nivel: number;
	nombre: string;
}

export interface ICatalogoCiclos {
	estado: boolean;
	id: number;
	idColegio: number;
	nombre: string;
	vigente: boolean;
	year: number;
}

export interface ICatalogoSecciones {
	estado: boolean;
	grado: number;
	id: number;
	idColegio: number;
	nombre: string;
	nombreGrado: string;
}

export interface IFormularioCatalogos {
	id: string; // O el tipo adecuado para id
	descripcion: string;
	fechaPago: string;
	tipo: string;
	nombre: string;
	valor: string;
	diaMaximo: string;
	fechaMaxima: string;
	year: string;
	idNivel: string;
	nivel: string;
	idCiclo: string;
	vigente: string;
	grado: string;
	estado: string;
}

const urlDescuentos = `${config.URL_BASE}descuentos`;
const urlCobros = `${config.URL_BASE}cobros`;
const urlGrados = `${config.URL_BASE}grados`;
const urlCiclos = `${config.URL_BASE}ciclo-escolar`;
const urlSecciones = `${config.URL_BASE}secciones`;
const urlNiveles = `${config.URL_BASE}niveles`;

// CATÁLOGOS FIND, CREATE, UPDATE
// COBROS
export async function findCobros(filter: any, filterActive?: boolean) {
	try {
		const request = new RequestAdmin();
		let url;
		if (filterActive) {
			url = `${urlCobros}?filter=${JSON.stringify(filter)}`;
		} else {
			url = urlCobros;
		}
		return await request.executeGet(url);
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function updateCreateCobros(body: { id: any }, tipo: number) {
	try {
		const request = new RequestAdmin();
		if (tipo === 1) {
			return request.executePostPutPatch(
				`${urlCobros}/${body.id}`,
				body,
				'PATCH'
			);
		}
		delete body.id;
		return request.executePostPutPatch(`${urlCobros}`, body, 'POST');
	} catch (err) {
		console.error('Error en Crear / Editar', err);
		showNotificationError('Algo ha salido mal', 'Error en Crear / Editar');
		return null;
	}
}

// DESCUENTOS
export async function findDescuentos(filter: any, filterActive?: boolean) {
	try {
		const request = new RequestAdmin();
		let url;
		if (filterActive) {
			url = `${urlDescuentos}?filter=${JSON.stringify(filter)}`;
		} else {
			url = urlDescuentos;
		}
		return await request.executeGet(url);
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function updateCreateDescuentos(body: { id: any }, tipo: number) {
	try {
		const request = new RequestAdmin();
		if (tipo === 1) {
			return request.executePostPutPatch(
				`${urlDescuentos}/${body.id}`,
				body,
				'PATCH'
			);
		}
		delete body.id;
		return request.executePostPutPatch(`${urlDescuentos}`, body, 'POST');
	} catch (err) {
		console.error('Error en Crear / Editar', err);
		showNotificationError('Algo ha salido mal', 'Error en Crear / Editar');
		return null;
	}
}

// CICLO ESCOLAR
export async function findCiclos(filter: any, filterActive?: boolean) {
	try {
		let url;
		if (filterActive) {
			url = `${urlCiclos}?filter=${JSON.stringify(filter)}`;
		} else {
			url = urlCiclos;
		}
		const request = new RequestAdmin();
		return await request.executeGet(url);
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function updateCreateCiclo(body: { id: any }, tipo: number) {
	try {
		const request = new RequestAdmin();
		if (tipo === 1) {
			return request.executePostPutPatch(
				`${urlCiclos}/${body.id}`,
				body,
				'PATCH'
			);
		}
		delete body.id;
		return request.executePostPutPatch(`${urlCiclos}`, body, 'POST');
	} catch (err) {
		console.error('Error en Crear / Editar', err);
		showNotificationError('Algo ha salido mal', 'Error en Crear / Editar');
		return null;
	}
}

// GRADOS
export async function findGrados(filter: any, filterActive?: boolean) {
	try {
		const request = new RequestAdmin();
		let url;
		if (filterActive) {
			url = `${urlGrados}?filter=${JSON.stringify(filter)}`;
		} else {
			url = urlGrados;
		}
		return await request.executeGet(url);
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function updateCreateGrados(body: { id: any }, tipo: number) {
	try {
		const request = new RequestAdmin();
		if (tipo === 1) {
			return request.executePostPutPatch(
				`${urlGrados}/${body.id}`,
				body,
				'PATCH'
			);
		}
		delete body.id;
		return request.executePostPutPatch(`${urlGrados}`, body, 'POST');
	} catch (err) {
		console.error('Error en Crear / Editar', err);
		showNotificationError('Algo ha salido mal', 'Error en Crear / Editar');
		return null;
	}
}

// SECCIONES
export async function findSecciones(filter: any, filterActive?: boolean) {
	try {
		const request = new RequestAdmin();
		let url;
		if (filterActive) {
			url = `${urlSecciones}?filter=${JSON.stringify(filter)}`;
		} else {
			url = urlSecciones;
		}
		return await request.executeGet(url);
	} catch (error) {
		console.log(error);
		return null;
	}
}

export async function updateCreateSecciones(body: { id: any }, tipo: number) {
	try {
		const request = new RequestAdmin();
		if (tipo === 1) {
			return request.executePostPutPatch(
				`${urlSecciones}/${body.id}`,
				body,
				'PATCH'
			);
		}
		delete body.id;
		return request.executePostPutPatch(`${urlSecciones}`, body, 'POST');
	} catch (err) {
		console.error('Error en Crear / Editar', err);
		showNotificationError('Algo ha salido mal', 'Error en Crear / Editar');
		return null;
	}
}

// NIVELES
export async function findNiveles(filter: any, filterActive?: boolean) {
	try {
		const request = new RequestAdmin();
		let url;
		if (filterActive) {
			url = `${urlNiveles}?filter=${JSON.stringify(filter)}`;
		} else {
			url = urlNiveles;
		}
		return await request.executeGet(url);
	} catch (err) {
		console.error('Error en obtener Niveles', err);
		return null;
	}
}

export const TIPOS_PAGOS_CATALOGOS = [
	{
		id: 'ANUAL',
		nombre: 'ANUAL',
	},
	{
		id: 'MENSUAL',
		nombre: 'MENSUAL',
	},
	{
		id: 'ESPECIAL',
		nombre: 'ESPECIAL',
	},
];
export const TIPOS_PAGOS_CATALOGOS2 = [
	{
		id: 'PORCENTAJE',
		nombre: 'PORCENTAJE',
	},
	{
		id: 'MONTO',
		nombre: 'MONTO',
	},
];
export const YEARS_CATALOGOS = [
	{
		id: '2024',
		nombre: '2024',
	},
	{
		id: '2025',
		nombre: '2025',
	},
	{
		id: '2026',
		nombre: '2026',
	},
	{
		id: '2027',
		nombre: '2027',
	},
	{
		id: '2028',
		nombre: '2028',
	},
];
export const MESES = [
	{ id: 1, nombre: 'Enero' },
	{ id: 2, nombre: 'Febrero' },
	{ id: 3, nombre: 'Marzo' },
	{ id: 4, nombre: 'Abril' },
	{ id: 5, nombre: 'Mayo' },
	{ id: 6, nombre: 'Junio' },
	{ id: 7, nombre: 'Julio' },
	{ id: 8, nombre: 'Agosto' },
	{ id: 9, nombre: 'Septiembre' },
	{ id: 10, nombre: 'Octubre' },
	{ id: 11, nombre: 'Noviembre' },
	{ id: 12, nombre: 'Diciembre' },
];
export const DIAS = Array.from({ length: 31 }, (_, i) => ({
	id: i + 1,
	name: i + 1,
}));
