import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	catalogos,
	dashboardPagesMenu,
	demoPagesMenu,
	estudiantesMenu,
	pageLayoutTypesPagesMenu,
	pagesEdu,
	depositos,
	USUARIOS,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';
import HeaderCatalogos from '../pages/_layout/_headers/Catalogos/HeaderCatalogos';

const catalogoRoutes = Object.values(catalogos.catalogos.subMenu).map(
	subMenuItem => ({
		path: subMenuItem.path,
		element: <HeaderCatalogos />,
	})
);

const headers: RouteProps[] = [
	// TABLA DE USUARIOS HEADER
	{
		path: pagesEdu.estudiantes.subMenu.vistaGeneral.path,
		element: <DashboardHeader isDashboardPage={true} />,
	},

	// TABLA DE LISTADO DE ESTUDIANTES y TABLA DE LISTADO DE INSCRIPCIONES
	{
		path: estudiantesMenu.estudiantesListado.subMenu.listadoEstudiantesIndex.path,
		element: <DashboardHeader isDashboardPage={true} />,
	},
	{
		path: estudiantesMenu.estudiantesListado.subMenu.listadoInscripcionesIndex.path,
		element: <DashboardHeader isDashboardPage={true} />,
	},

	// TABLA DE DEPÓSITOS
	{ path: depositos.depositos.path, element: <DashboardHeader isDashboardPage={true} /> },

	// CATÁLOGOS
	...catalogoRoutes,
	// USUARIOS
	{ path: USUARIOS.USUARIOS.path, element: <DashboardHeader isDashboardPage={true} /> },
	{ path: 'detalle-usuario/:id', element: <DashboardHeader isDashboardPage={false} /> },
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: null,
	},
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: demoPagesMenu.login.path, element: null },
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <DashboardHeader isDashboardPage={false} />,
	},
	{
		path: `*`,
		element: null,
	},
];

export default headers;
