import config from "../config";
import RequestAdmin from "../request";

export function login(email: string, password: string) {
  const request = new RequestAdmin();
  try {
    const body = {
      email,
      password,
    };
    return request.executePostPutPatch(
      `${config.URL_BASE}usuarios/login`,
      body,
      "POST",
        true
    );
  } catch (err: any) {
      if (err.response && err.response.status === 401) {
          return err.response.data;
      } else {
          console.error("Error en login", err);
          throw err; // Re-lanzar el error si no es un 401 o si deseas manejarlo en otro lugar
      }

  }
}
