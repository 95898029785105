import { Store } from 'react-notifications-component';
import Icon from "../../components/icon/Icon";

const showNotification = (
    title: string | JSX.Element,
    message: string | JSX.Element,
) => {

    const TITLE = (
        <span className='d-flex align-items-center'>
			<Icon icon='ErrorOutline' size='lg' className='me-1'/>
			<span className='text-uppercase'>{title}</span>
		</span>
    );
    const MESSAGE = (
        <div>
        <p>{message}</p>
        </div>
    )
    Store.addNotification({
        title: TITLE,
        message: MESSAGE,
        // @ts-ignore
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,
            pauseOnHover: true,
            onScreen: true,
            showIcon: true,
            waitForAnimation: true,
        },
    });
};

export default showNotification;
